@import "../variables";
@import "../mixins/sprite-icon";
@import "../../bootstrap/mixins";


.title-section {
	padding-top: $bu*2;

	@include media-breakpoint-up(lg) {
		padding-top: $bu*4;
	}
}

.table {
	.pricebox {
		[set="1"] {
			.box-platforms {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}
	}
}


//
//	Product boxes (section)
//
.product-boxes-section {
	[class*="tagline-"] {
		margin-bottom: $bu;

		@include media-breakpoint-up(lg) {
			margin-bottom: $bu*1.5;
		}
	}

	h2 {
		margin-bottom: $bu*.75;

		@include media-breakpoint-up(lg) {
			margin-bottom: $bu;
		}
	}

	.body-1 {
		margin-bottom: $bu*2.5;

		@include media-breakpoint-up(lg) {
			margin-bottom: $bu*3.5;
		}
	}
}
//
// Awards – Info icon in the text
//
.award-tool-wrap {
	display: inline-block;
	white-space: nowrap;

	.award-text {
		white-space: normal;
	}

	.info-icon {
		display: inline-block;
		vertical-align: middle;

		@include sprite-icon('info', 16);
	}
}

#section-awards {

	// Awards tooltip icon in non A1 locales
	.award-tooltip {
		display: inline-block;
		width: $bu;
		height: $bu;
		margin-inline-start: $bu/4;
		position: relative;
		top: $bu/8;

		@include sprite-icon("info", 16);
		cursor: pointer;

		&:hover,
		&:focus {
			@include sprite-icon("hover-info", 16);
		}
	}	
}


#section-smb {
	.container {
		@include media-breakpoint-up(sm) {
			border-radius: $bu * 1.5;
		}

		@include media-breakpoint-up(lg) {
			border-radius: $bu * 3;
		}
	}
}


//
// Enable Trial buttons only on specific platforms
//
html {
	&:not(.js-pc) {
		.btn.pc-enabled {
			cursor: initial;
			pointer-events: none;
			opacity: $btn-disabled-opacity;
		}
	}

	&:not(.js-mac) {
		.btn.mac-enabled {
			cursor: initial;
			pointer-events: none;
			opacity: $btn-disabled-opacity;
		}
	}
}


//
// EN-GB locale –– hide price per month (legal requirement for GB)
//
.mod-en-gb {
	.simple-fix-pricebox {
		.box-note-under-price {
			display: none;
		}
	}
}


//
// RU locales –– fixes
//
[lang=ru-ru],
[lang=ru-ua],
[lang=ru-kz] {
	// Trustpilot – fix cropped widget content
	.trustpilot-widget.size-sm:not(.long):not(.vertical) iframe {
		height: 92px;
	}

	// Fix long words overlapping box content
	h2 {
		@include media-breakpoint-down(sm) {
			hyphens: auto;
		}
	}

	// Fix: no paddings on Android CTA download button
	#section-privacy-products {
		.productbox.js-android {
			.btn.full {
				@include media-breakpoint-down(sm) {
					padding-left: $bu;
					padding-right: $bu;
				}
			}
		}
	}
}